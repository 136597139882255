import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
        
<path d="M1415 2376 c-177 -35 -334 -117 -463 -240 -398 -380 -393 -1029 10
-1412 125 -118 285 -204 452 -241 106 -24 309 -21 410 6 188 50 388 183 507
337 31 40 39 57 31 67 -12 15 -4 31 18 35 11 2 10 -1 -3 -12 -10 -8 -14 -17
-9 -20 15 -9 70 92 110 199 37 100 46 162 46 320 -1 143 -4 169 -27 250 -37
125 -115 276 -192 373 -122 151 -310 275 -490 323 -103 27 -300 34 -400 15z
m405 -33 c284 -82 476 -283 545 -570 10 -40 22 -70 26 -67 13 8 12 3 -22 -72
-17 -38 -57 -107 -89 -154 -101 -149 -129 -229 -115 -336 9 -73 20 -55 28 46
5 68 42 188 55 176 3 -3 -5 -28 -17 -55 -27 -63 -42 -186 -44 -366 -2 -129 -4
-144 -29 -198 -77 -164 -291 -267 -557 -267 -203 0 -376 58 -543 181 -394 292
-505 859 -248 1275 215 349 631 516 1010 407z m628 -633 c8 -12 10 -20 4 -16
-13 8 -35 -20 -29 -36 4 -8 6 -7 6 5 1 9 6 17 11 17 9 0 8 -26 -5 -112 -3 -16
0 -28 5 -28 6 0 10 21 10 48 0 26 5 54 11 62 8 11 9 6 4 -20 -5 -27 -4 -32 5
-20 9 12 10 8 6 -20 -16 -96 -16 -96 -2 -55 l14 40 1 -57 c1 -31 6 -69 12 -85
6 -15 12 -50 15 -78 l3 -50 -14 40 c-7 22 -14 50 -14 61 -1 12 -9 28 -18 35
-15 12 -16 12 -9 -6 5 -11 17 -51 28 -90 19 -68 19 -72 3 -145 -23 -99 -64
-202 -78 -193 -6 4 -8 0 -4 -11 5 -11 3 -15 -5 -10 -8 5 -9 2 -5 -10 5 -11 3
-15 -5 -10 -8 5 -9 2 -5 -10 4 -9 3 -15 -2 -11 -5 3 -11 -1 -13 -7 -3 -7 -5 2
-5 20 0 21 3 28 9 20 13 -21 9 -3 -7 33 -16 34 -16 34 -10 4 8 -39 -1 -29 -20
21 l-15 39 6 -37 c8 -47 -2 -50 -15 -5 -6 20 -9 24 -10 12 0 -11 10 -40 24
-64 28 -48 29 -51 17 -51 -5 0 -14 12 -21 26 -6 15 -14 24 -17 21 -3 -3 2 -17
12 -32 20 -30 26 -68 11 -58 -6 4 -8 0 -4 -10 3 -9 1 -19 -5 -23 -7 -4 -8 -3
-4 4 4 7 3 12 -2 12 -16 0 -22 -13 -12 -25 8 -9 4 -12 -12 -11 -15 1 -22 -4
-21 -14 0 -8 -17 -30 -38 -49 -26 -22 -37 -27 -33 -16 6 14 4 16 -6 10 -10 -6
-11 -4 -5 11 5 10 9 35 9 56 1 20 3 35 6 33 3 -2 13 10 22 26 10 16 13 29 9
29 -4 0 -13 -10 -20 -22 -9 -19 -10 -20 -7 -3 18 75 18 80 8 70 -5 -5 -12 -19
-15 -30 -5 -20 -12 62 -9 100 15 172 23 230 42 274 12 27 19 54 16 60 -7 11
12 56 21 48 2 -3 -1 -15 -7 -27 -10 -18 -9 -21 1 -15 9 6 10 3 5 -11 -18 -43
-16 -47 4 -11 33 60 33 38 0 -39 -16 -38 -29 -76 -29 -84 0 -8 7 4 16 28 8 24
17 41 20 38 3 -3 -2 -26 -11 -52 -9 -26 -16 -55 -15 -63 1 -9 5 -5 9 9 l8 25
1 -24 c1 -30 6 -25 15 14 5 22 4 26 -4 15 -9 -13 -10 -13 -9 0 0 8 4 24 9 35
9 20 9 20 10 0 0 -15 5 -11 15 14 19 42 19 54 0 21 -26 -46 -14 4 16 65 16 33
27 50 25 37 -2 -13 0 -20 6 -16 6 4 8 -2 3 -17 -5 -19 -4 -21 4 -9 10 13 11
13 11 0 0 -13 1 -13 11 0 8 11 9 8 5 -15 -5 -22 -4 -26 4 -15 16 25 11 -2 -15
-80 -26 -77 -33 -121 -10 -60 10 25 14 29 15 15 1 -14 5 -10 15 15 16 42 18
61 5 40 -9 -13 -10 -13 -9 0 0 8 4 24 8 35 7 17 9 15 16 -15 7 -30 9 -32 16
-15 8 20 8 20 9 0 1 -18 2 -17 11 4 13 32 4 71 -36 156 -32 69 -90 155 -83
124 5 -23 -53 -124 -62 -109 -14 23 -89 -147 -99 -225 -7 -62 -9 -64 -10 -24
0 26 8 76 19 112 24 76 114 222 132 211 7 -5 9 -2 5 8 -3 9 0 24 7 33 11 16
16 13 47 -26 45 -57 72 -118 94 -207 19 -76 32 -99 20 -34 -15 86 -51 172 -97
233 -32 43 -35 54 -10 45 16 -6 16 -5 0 8 -10 7 -18 18 -18 25 0 6 8 12 18 12
15 1 15 2 1 6 -15 4 -15 8 3 46 12 23 26 45 32 49 6 5 7 8 1 8 -11 0 -41 60
-37 74 2 5 -2 12 -9 16 -6 4 -8 13 -4 20 5 8 17 -4 34 -38 14 -27 32 -60 39
-72z m-46 -265 c25 -49 58 -143 57 -164 0 -12 -3 -11 -9 4 -9 20 -9 20 -10 0
-2 -21 -38 68 -39 95 -1 13 0 13 9 0 15 -22 12 -4 -5 37 -8 20 -18 34 -21 30
-3 -3 -1 -14 5 -25 14 -26 14 -42 1 -22 -8 11 -9 7 -5 -15 5 -27 3 -26 -13 9
l-18 40 -29 -68 c-17 -36 -31 -62 -33 -57 -2 5 4 23 12 40 9 16 13 37 9 47 -6
16 -7 15 -20 -2 -25 -34 -13 -3 24 62 42 72 42 72 85 -11z"/>
<path d="M1193 1860 c-13 -5 -23 -14 -23 -19 0 -6 7 -7 17 -4 11 4 14 3 9 -5
-9 -15 2 -52 14 -52 5 0 7 14 3 30 -4 23 -1 34 13 45 22 17 3 20 -33 5z"/>
<path d="M1310 1850 c-11 -54 -9 -70 9 -70 11 0 23 5 26 10 3 6 -1 10 -10 10
-9 0 -13 4 -10 10 3 5 1 18 -4 27 -6 10 -11 16 -11 13z"/>
<path d="M1443 1832 c-7 -4 -15 -21 -19 -38 -5 -25 -2 -32 20 -42 16 -7 28 -8
31 -2 4 6 3 10 -2 10 -4 0 -16 7 -26 14 -19 15 -13 46 9 46 8 0 14 5 14 10 0
12 -10 13 -27 2z"/>
<path d="M1571 1771 c-7 -5 -10 -14 -7 -20 10 -16 26 -13 26 5 0 11 6 14 21 9
15 -5 19 -4 15 4 -8 13 -36 14 -55 2z"/>
<path d="M1265 1677 c-22 -13 -52 -42 -68 -69 -74 -120 0 -283 139 -304 50 -7
114 7 138 31 13 13 15 -9 16 -181 1 -177 -1 -198 -17 -210 -17 -13 -17 -14 0
-8 15 6 17 -3 17 -100 0 -64 4 -106 10 -106 6 0 10 42 10 106 l0 106 35 -6
c35 -7 49 6 20 18 -11 4 -15 22 -15 70 0 61 2 65 35 95 20 17 42 31 50 31 19
0 41 -35 55 -90 7 -25 21 -62 33 -82 17 -32 26 -38 53 -38 18 0 36 5 39 10 3
6 -1 10 -9 10 -22 0 -44 38 -66 110 -23 75 -43 100 -82 100 -30 0 -29 1 37 73
37 39 77 77 89 84 18 11 14 12 -33 13 -49 0 -53 -1 -36 -14 21 -15 22 -14
-107 -155 l-58 -64 0 101 c0 93 2 102 22 116 l22 16 -21 0 c-48 0 -48 1 -47
-196 0 -101 -3 -187 -8 -190 -4 -3 -8 90 -8 206 0 201 1 212 22 240 30 41 37
134 13 181 -55 109 -181 152 -280 96z m151 -18 c65 -40 75 -65 72 -186 l-3
-107 -35 -24 c-68 -46 -160 -12 -204 76 -46 93 -42 172 13 231 25 26 36 31 76
31 31 0 60 -7 81 -21z m113 -149 c0 -25 -4 -54 -9 -65 -6 -13 -9 9 -9 65 0 56
3 78 9 65 5 -11 9 -40 9 -65z"/>
<path d="M1666 1681 c-19 -21 -14 -41 11 -41 13 0 13 3 3 15 -14 16 -10 18 34
15 20 -1 27 2 24 12 -7 21 -52 21 -72 -1z"/>
<path d="M1757 1592 c-20 -22 -13 -32 24 -32 29 0 63 26 54 41 -10 16 -61 10
-78 -9z"/>
<path d="M1874 1456 c-23 -20 -27 -27 -16 -37 10 -11 12 -11 12 0 0 7 10 21
23 31 12 10 19 21 16 25 -4 3 -20 -5 -35 -19z"/>
<path d="M2428 1501 c15 -21 28 -35 30 -30 4 10 -39 68 -50 68 -4 1 5 -16 20
-38z"/>
<path d="M2236 1259 c-9 -29 -15 -69 -15 -88 1 -29 3 -25 10 19 4 30 13 68 19
84 6 16 8 31 6 33 -3 3 -12 -19 -20 -48z"/>
<path d="M2311 1215 c-12 -37 -21 -75 -20 -84 1 -9 4 -6 9 9 l8 25 2 -25 c1
-21 4 -19 16 15 l13 40 -4 -40 c-5 -43 6 -25 19 30 5 22 4 26 -4 15 -8 -13
-10 -12 -11 5 0 14 -5 9 -14 -15 -19 -48 -19 -13 0 40 8 24 13 45 11 48 -3 2
-14 -26 -25 -63z"/>
<path d="M2405 1170 c-14 -45 -21 -57 -25 -44 -6 16 -8 14 -13 -8 -4 -15 -3
-36 1 -48 7 -18 12 -8 32 55 17 53 26 71 30 59 6 -15 8 -13 14 10 4 16 5 31 1
34 -3 3 -5 -3 -6 -14 0 -18 -1 -18 -8 0 -5 14 -12 3 -26 -44z"/>
<path d="M2432 1122 c-25 -78 -36 -102 -44 -91 -8 11 -9 10 -4 -4 3 -10 6 -24
6 -30 1 -7 7 2 15 18 7 17 14 23 14 15 1 -13 3 -13 10 0 5 8 12 26 15 40 6 19
5 21 -3 10 -10 -13 -11 -13 -10 0 0 8 4 24 9 35 9 20 9 20 10 0 1 -13 6 -7 16
19 8 24 11 49 6 66 -6 24 -11 13 -40 -78z"/>
<path d="M2252 1105 c-1 -34 5 -73 14 -90 l14 -30 -6 30 c-4 17 -10 57 -14 90
l-7 60 -1 -60z"/>
<path d="M2220 1059 c0 -40 4 -68 9 -65 5 4 11 3 14 -1 2 -5 2 -2 0 5 -1 8 -3
40 -3 73 0 33 -4 59 -10 59 -6 0 -10 -30 -10 -71z"/>
<path d="M2280 1068 c3 -24 12 -52 19 -63 13 -20 13 -20 6 0 -4 11 -10 39 -12
63 -3 23 -9 42 -13 42 -4 0 -4 -19 0 -42z"/>
<path d="M2357 1483 c4 -11 3 -14 -5 -9 -7 4 -18 -6 -27 -25 -10 -18 -12 -28
-5 -24 9 5 10 1 6 -16 -5 -17 -1 -14 12 9 l18 33 12 -23 c12 -22 9 -6 -5 29
-4 11 -3 14 6 8 10 -6 11 -3 2 14 -14 25 -23 28 -14 4z"/>
</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
